@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
}
a {
  cursor: pointer;
}

/* For Webkit browsers (Chrome, Safari, etc.) */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

:root {
  --primary-color: #c70039;
  --primaryHover-color: #b30335;
  --secondary-color: #f5f5f5;
  --white-color: white;
  --black-color: #222831;
  --primaryTra-color: #c7003828;
  --shadow-color: var(--primaryTra-color) 0px 7px 29px 0px;
}
body {
  background-color: var(--black-color);
  height: fit-content;
}
.ShadowCLass {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}
.loader::before,
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid var(--primary-color);
  animation: prixClipFix 2s linear infinite;
}
.loader::after {
  inset: 8px;
  transform: rotate3d(90, 90, 0, 180deg);
  border-color: var(--primary-color);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  75%,
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
}

/* .loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: var(--primary-color);
}
.loader:before,
.loader:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}
.loader:after {
  color: var(--primary-color);
  transform: rotateY(70deg);
  animation-delay: 0.4s;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: 0.2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: 0.2em 0.2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 0.2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -0.2em 0.2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -0.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -0.2em -0.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -0.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: 0.2em -0.2em 0 0 currentcolor;
  }
} */

.category.active {
  background-color: var(--primary-color);
  color: white;
}

.container {
  width: 90%;
  margin: 0 auto;
}

@keyframes dropCircle {
  0% {
    border-radius: 100%;
    bottom: 10px;
    height: 10px;
    left: 50%;
    width: 10px;
  }
  50% {
    border-radius: 100%;
    bottom: 0px;
    height: 10px;
    left: 50%;
    width: 10px;
  }
  60% {
    height: 3px;
  }
  100% {
    border-radius: 2px;
    bottom: 0px;
    height: 3px;
    left: 50%;
    width: 60%;
  }
}

.HeaderShadow {
  box-shadow: rgba(177, 177, 184, 0.2) 0px 4px 10px 0px;
  box-shadow: var(--shadow-color);
}

.error {
  border-color: red;
  animation: bounce 0.5s ease-out backwards;
}

@keyframes bounce {
  0% {
    transform: translateX(0);
  }
  0% {
    transform: translateX(12px);
  }
  20% {
    transform: translateX(-12px);
  }
  40% {
    transform: translateX(8px);
  }
  60% {
    transform: translateX(-8px);
  }
  80% {
    transform: translateX(4px);
  }
  100% {
    transform: translateX(0px);
  }
}

.show {
  animation: showMessage 1.2s ease-in-out forwards;
}

@keyframes showMessage {
  0% {
    top: -5rem;
  }
  20% {
    top: 3.5rem;
  }
  80% {
    top: 3.5rem;
  }
  100% {
    top: -5rem;
  }
}
.ShadoBotton {
  box-shadow: var(--primary-color) 1px 2px 10px;
}
.ShadoBottonError {
  box-shadow: rgba(212, 14, 14, 0.77) 1px 2px 10px;
}

.loaderButton {
  animation: l20-1 0.8s infinite linear alternate, l20-2 1.6s infinite linear;
}

@keyframes l20-1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }
  12.5% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 0%,
      100% 0%,
      100% 0%
    );
  }
  25% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      100% 100%,
      100% 100%
    );
  }
  50% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }
  62.5% {
    clip-path: polygon(
      50% 50%,
      100% 0,
      100% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }
  75% {
    clip-path: polygon(
      50% 50%,
      100% 100%,
      100% 100%,
      100% 100%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }
  100% {
    clip-path: polygon(
      50% 50%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      0% 100%
    );
  }
}
@keyframes l20-2 {
  0% {
    transform: scaleY(1) rotate(0deg);
  }
  49.99% {
    transform: scaleY(1) rotate(135deg);
  }
  50% {
    transform: scaleY(-1) rotate(0deg);
  }
  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}

.swiper-pagination-bullet {
  background-color: var(--white-color) !important;
  width: 15px !important;
  height: 15px !important;
  border: 2px solid var(--primary-color) !important;
  cursor: pointer !important;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-scroll {
  display: flex;
  animation: scroll 30s linear infinite;
}
.animate-scroll img {
  filter: drop-shadow(5px 5px 30px rgba(0, 0, 0, 0.7));
}
.category1::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  background-color: var(--primary-color);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.category2::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 40px 35px 60px;
  border-color: var(--primary-color) transparent transparent transparent;
  transform: translate(-50%, 80%);
  z-index: -1;
}

.category3::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 50px;
  height: 50px;
  background-color: var(--primary-color);
  transform: translate(-50%, -70%) rotate(22deg); /* Rotate the square to make it a diamond */
  z-index: -1;
}
.course-item {
  max-width: 330px !important;
}

.dshb.active {
  /* background-color: var(--secondary-color); */
  color: var(--primary-color);
  transition: 0.5s;
}

@media (min-width: 868px) {
  .link:hover > a:first-child,
  .ListHide > a:hover,
  .link > a.active {
    transition: color 0.3s;
    color: var(--primary-color);
  }
  .link:hover > .ListHide {
    display: block !important;
  }
  .link.active::after {
    content: "";
    position: absolute;
    border-radius: 1px;
    background-color: var(--primary-color);
    transform: translateX(-50%);
    z-index: 10;
    animation: dropCircle 0.4s ease-in-out forwards;
  }
}

.navbar {
  height: calc(100vh - 100px);
}
.subLink {
  position: relative;
  padding: 10px 0;
}
.subLink::after {
  content: "";
  position: absolute;
  width: 0%;
  height: 2px;
  background-color: var(--primary-color);
  left: 0;
  bottom: 4px;
  transition: 0.5s;
}
.subLink.active::after {
  width: 100%;
}
.subLink.active {
  color: var(--primary-color);
}

@media (max-width: 867px) {
  .NavBar {
    flex-direction: column;
    position: absolute;
    width: 100%;
    background-color: transparent;
    z-index: 9999999;
    height: calc(100dvh - 70px);
    bottom: -1px;
    transform: translateY(-100%);
    transition: transform 0s ease-in-out;
    justify-content: space-evenly;
  }

  .NavBar.active {
    transform: translateY(100%);
  }

  .link {
    width: 100%;
    color: var(--secondary-color);
    background-color: #383636f1;
    font-size: 3rem;
    font-weight: 400;
    height: calc(105% / 5);
    position: relative;
    margin-top: -0.5px;
    opacity: 0;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.5),
      0 0 10px rgba(255, 255, 255, 0.4), 0 0 15px rgba(255, 255, 255, 0.3),
      0 0 20px rgba(255, 255, 255, 0.2);
    z-index: 999999999999999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .link:nth-child(1),
  .link:nth-child(3),
  .link:nth-child(5) {
    transform: translateX(-100%);
  }

  .link:nth-child(2),
  .link:nth-child(4) {
    transform: translateX(100%);
  }

  .NavBar.active .link {
    opacity: 1;
    transform: translateX(0%);
    transition-delay: 0.05s;
  }

  .link.exiting {
    opacity: 0;
    transition-delay: 0s;
  }

  .link.exiting:nth-child(1),
  .link.exiting:nth-child(3),
  .link.exiting:nth-child(5) {
    transform: translateX(-100%);
  }

  .link.exiting:nth-child(2),
  .link.exiting:nth-child(4) {
    transform: translateX(100%);
  }

  .link:hover {
    letter-spacing: 20px;
  }
}

.likeActive {
  animation: like 1s ease-in-out forwards;
}
@keyframes like {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  30% {
    transform: translate(-50%, -50%) scale(1.1);
  }
  50% {
    transform: translate(-50%, -50%) scale(0.9);
  }
  70% {
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    transform: translate(-50%, -50%) scale(0);
  }
}

.ListHide a:last-of-type {
  border: none;
}
.swiper-button-next,
.swiper-button-prev {
  color: var(--primary-color) !important;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  color: var(--primaryHover-color) !important;
}

.animate-scroll-right {
  display: flex;
  animation: scrollRight 30s linear infinite;
}

@keyframes scrollField {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-33.33%);
  }
}

@keyframes scrollReverse {
  0% {
    transform: translateX(-33.33%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes scrollRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
